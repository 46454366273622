import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import Tabela from "../../components/Tabela/TabelaNotasEnviadas";
import Menu from "../../components/Menu/Menu";
import Navbar from "../../components/Navbar/Navbar";
import moment from 'moment';
import csv from 'csv-parser';
import "react-widgets/styles.css";
import Icon, { FontAwesome, Feather, AntDesign } from "react-web-vector-icons";
import Contexto from "../../components/Contexto/Contexto";
import { useApp } from '../../context/AppContext';

const api = process.env.REACT_APP_HOST;
const tbc = process.env.REACT_APP_HOST_2;

export default function Movimentos() {
  const history = useHistory();
  // const { user } = useApp();
  const [qtdmovimentos, setQtdmovimentos] = useState(0);
  const [dadostabela, setDadostabela] = useState([]);
  const [dadosprefeitura, setDadosprefeitura] = useState([]);
  const [dadostotfat, setDadostotfat] = useState([]);
  const [dadostotfatserv, setDadostotfatserv] = useState([]);
  const [isloadtabela, setIsloadtabela] = useState(false);

  useEffect(() => {
    const codusuario = localStorage.getItem("@integrador-app/codusuario");
    if (codusuario === null) {
      history.push("/entrar");
    } else {
      const coligada = localStorage.getItem("@integrador-app/codcoligada");
      if (coligada === null) {
        Swal.fire({
          title:
            '<span style="font-size: 22px;">Notas Autorizadas</span>',
          html: '<p style="font-size: 16px;">Você deve informar um contexto para a exibição dos movimentos.</p>',
          icon: "error",
          confirmButtonText: "Sair",
        });
      } else {
        setIsloadtabela(true);

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Basic YTI4MDVhMTgtYjUwMC00ZGM0LWI3NDUtMjNmZDc0MjAwNzAw");

        var reqOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };

        fetch(`https://api.enotasgw.com.br/v1/estados/cidades/${localStorage.getItem("@integrador-app/codmunicip")}/Provedor`, reqOptions)
          .then(response => response.json())
          .then(result => {
            setDadosprefeitura(result);
            // console.log(result);
          })
          .catch(error => console.log('error', error));

        // Carrego o Movimento
        const params = {
          "codcoligada": localStorage.getItem("@integrador-app/codcoligada"),
          "codfilial": localStorage.getItem("@integrador-app/codfilial"),
          "status": "A",
          "codtitmov": localStorage.getItem("@integrador-app/codtpmov"),
          "dataini": localStorage.getItem("@integrador-app/dtini"),
          "datafim": localStorage.getItem("@integrador-app/dtfim")
        }

        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(params),
        };
        fetch(`${api}/notasfiscaisporstatus`, requestOptions)
          .then(response => response.json())
          .then(
            result => {
              if (result.status_code == "400") {
                setQtdmovimentos(0);
                setDadostabela([]);
                localStorage.setItem('@integrador-app/qtdmov', result.record_count);
                setIsloadtabela(false);
              } else {
                setDadostabela(result.dados);
                setQtdmovimentos(result.record_count)
                localStorage.setItem('@integrador-app/qtdmov', result.record_count);
                setIsloadtabela(false);
              }
            });

        /* Totaliza o Total Faturado por Serviço */
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Cookie", "ci_session=rjbl8vc5ogh5p2j5j3nqnbng3ccth84b");

        const requestServicosTotal = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(params),
        };

        fetch(`${api}/totalfaturadoporservico`, requestServicosTotal)
          .then(response => response.json())
          .then(
            result => {
              setDadostotfatserv(result.dados);
            })
          .catch(error => console.log('error', error));

        /* Totaliza o Total Faturado */
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Cookie", "ci_session=rjbl8vc5ogh5p2j5j3nqnbng3ccth84b");

        const requestTotal = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(params),
        };

        fetch(`${api}/totalfaturado`, requestTotal)
          .then(response => response.json())
          .then(
            result => {
              setDadostotfat(result.dados);
            })
          .catch(error => console.log('error', error));
      }
    }
  }, []);

  const CancelamentoNfseLote = () => {
    const [progress, setProgress] = useState(0);

    const handleFileUpload = (event) => {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onloadstart = () => {
        setProgress(0);
      };

      reader.onprogress = (event) => {
        if (event.lengthComputable) {
          const percentLoaded = Math.round((event.loaded / event.total) * 100);
          setProgress(percentLoaded);
        }
      };

      reader.onload = (event) => {
        const csvData = event.target.result;

        // Processamento dos dados do CSV
        const results = [];

        csvData
          .split('\n') // Divide as linhas do CSV em um array
          .forEach((row, index) => {
            if (index === 0) return; // Pula o cabeçalho
            const columns = row.split(';'); // Divide as colunas do CSV em um array
            const idMovimento = columns[2]; // Índice da coluna "ID_MOVIMENTO"
            //console.log(idMovimento);
            handleCancelaNota(idMovimento)
          });
        setProgress(100);
        Swal.fire({
          title: '<span style="font-size: 22px;">Cancelamento de Nota</span>',
          html: `<p align="center" style="font-size: 16px;">Processo de cancelamento em lote concluido com sucesso!!!</p>`,
          icon: "success",
          confirmButtonText: "Sair",
        });
      };

      reader.readAsText(file);
    };

    return (
      <div>
        <input
          type="file"
          accept=".csv"
          onChange={handleFileUpload}
          style={{ margin: '10px' }}
        />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <progress
            value={progress}
            max="100"
            style={{ width: '98%', height: '40px', margin: '10px' }}
          />
          <span style={{ marginLeft: '10px' }}>{progress}%</span>
        </div>
      </div>
    );
  };

  function CancelaNota(id) {
    const prefeitura_dados = JSON.parse(dadosprefeitura);

    if (dadosprefeitura !== null) {
      Swal.fire({
        title:
          '<span style="font-size: 22px;">Cancelamento de Notas</span>',
        html: '<p style="font-size: 16px;">A prefeitura suporta cancelamento de notas.</p>',
        icon: "success",
        confirmButtonText: "Sair",
      });
    } else {
      Swal.fire({
        title:
          '<span style="font-size: 22px;">Cancelamento de Notas</span>',
        html: '<p style="font-size: 16px;">Não foi possivel carregar os dados da prefeitura.</p>',
        icon: "error",
        confirmButtonText: "Sair",
      });
    }
  }

  function handleCancelaNota(nota) {
    /*
    if (dadosprefeitura.suportaCancelamento) {
      console.log(`Nota ${nota} concelada com sucesso.`);
    } else {
      console.log(`Esta prefeitura não suporta o cancelamento de notas, favor entrar em contato com a mesma para realizar o procedimento.`);
    }
    */

    if (dadosprefeitura.suportaCancelamento) {
      //console.log(dadosPrefeitura);
      var myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", "Basic YTI4MDVhMTgtYjUwMC00ZGM0LWI3NDUtMjNmZDc0MjAwNzAw");

      var raw = "";

      var requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(`https://api.enotasgw.com.br/v1/empresas/${localStorage.getItem(
        "@integrador-app/idempresa"
      )}/nfes/porIdExterno/${nota}`, requestOptions)
        .then((response) => {
          if (response.status === 200) {
            // Troco o Status da Nota para Pendente
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var rawnotascanceladas = JSON.stringify({
              "codcoligada": localStorage.getItem("@integrador-app/codcoligada"),
              "idmov": nota,
              "status": "C",
              "numnfse": "",
              "xmlnfse": null,
              "obs": null,
              "numprotocolo": "",
              "dtautoriza": "2021-03-10"
            });

            var requestOptions = {
              method: 'POST',
              headers: myHeaders,
              body: rawnotascanceladas,
              redirect: 'follow'
            };

            fetch(`${api}/atualizastatusnfse`, requestOptions)
              .then(response => response.json())
              .then(result => {
                console.log(result)
                if (result.status_code === 200) {
                  console.log(`A nota ${nota} foi cancelada com sucesso!!!`);
                } else {
                  console.log(`A nota ${nota} foi cancelada porém não foi possível mudar o status da naota.`);
                }
              })
              .catch(error => console.log('error', error));
          } else {
            console.log(`Não foi possível solicitar o cancelamento da nota ${nota}, pois a mesma já se encontra com o status de cancelada.`);
          }
        })
        .catch(error => {
          console.log(`Não foi possível cancelar da nota ${nota}.`, error[0].mensagem);
        });
    } else {
      console.log(`Esta prefeitura não suporta o cancelamento de notas, favor entrar em contato com a mesma para realizar o procedimento.`);
    }
  }

  function CarregarMovimento() {
    const coligada = localStorage.getItem("@integrador-app/codcoligada");
    if (coligada === null) {
      Swal.fire({
        title:
          '<span style="font-size: 22px;">Notas Autorizadas</span>',
        html: '<p style="font-size: 16px;">Você deve informar um contexto para a exibição dos movimentos.</p>',
        icon: "error",
        confirmButtonText: "Sair",
      });
    } else {
      setIsloadtabela(true);
      var myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", "Basic YTI4MDVhMTgtYjUwMC00ZGM0LWI3NDUtMjNmZDc0MjAwNzAw");

      var reqOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      fetch(`https://api.enotasgw.com.br/v1/estados/cidades/${localStorage.getItem("@integrador-app/codmunicip")}/Provedor`, reqOptions)
        .then(response => response.json())
        .then(result => {
          setDadosprefeitura(result);
          // console.log(result);
        })
        .catch(error => console.log('error', error));

      // Carrego o Movimento
      const params = {
        "codcoligada": localStorage.getItem("@integrador-app/codcoligada"),
        "codfilial": localStorage.getItem("@integrador-app/codfilial"),
        "status": "A",
        "codtitmov": localStorage.getItem("@integrador-app/codtpmov"),
        "dataini": localStorage.getItem("@integrador-app/dtini"),
        "datafim": localStorage.getItem("@integrador-app/dtfim")
      }
      console.log(params);
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(params),
      };
      fetch(`${api}/notasfiscaisporstatus`, requestOptions)
        .then(response => response.json())
        .then(
          result => {
            if (result.status_code == "400") {
              setQtdmovimentos(0);
              setDadostabela([]);
              localStorage.setItem('@integrador-app/qtdmov', result.record_count);
              setIsloadtabela(false);
            } else {
              setDadostabela(result.dados);
              setQtdmovimentos(result.record_count);
              localStorage.setItem('@integrador-app/qtdmov', result.record_count);
              setIsloadtabela(false);
            }
          });

      /* Totaliza o Total Faturado por Serviço */
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Cookie", "ci_session=rjbl8vc5ogh5p2j5j3nqnbng3ccth84b");

      const requestServicosTotal = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(params),
      };

      fetch(`${api}/totalfaturadoporservico`, requestServicosTotal)
        .then(response => response.json())
        .then(
          result => {
            setDadostotfatserv(result.dados);
          })
        .catch(error => console.log('error', error));

      /* Totaliza o Total Faturado */
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Cookie", "ci_session=rjbl8vc5ogh5p2j5j3nqnbng3ccth84b");

      const requestTotal = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(params),
      };

      fetch(`${api}/totalfaturado`, requestTotal)
        .then(response => response.json())
        .then(
          result => {
            setDadostotfat(result.dados);
          })
        .catch(error => console.log('error', error));
    }
  }

  return (
    <div id="wrapper">
      <Menu />
      <header class="topbar-nav">
        <Navbar />
      </header>
      <div class="clearfix"></div>
      <div class="content-wrapper">
        <div class="container-fluid">
          <Contexto />
          <div class="row">
            <div class="col-12 col-lg-12">
              <div class="card">
                <div class="card-header">
                  Informações de Faturamento
                </div>
                <div class="row" style={{ margin: '15px' }}>
                  <div class="col-md-6">
                    <h5>Permite cancelamento?

                      <h4>{dadosprefeitura.suportaCancelamento ?
                        <FontAwesome
                          name='check'
                          color='#1ab883'
                          size={30}
                        /> :
                        <FontAwesome
                          name='remove'
                          color='#e35252'
                          size={30}
                        />
                      }</h4>
                    </h5>
                    <h5>Total Faturado no Período</h5>
                    <p>
                      {dadostotfat.map(function (item, i) {
                        return (
                          <h4>{item.TotalServicoFaturado}</h4>
                        );
                      })}
                    </p><br />
                    <h5>Total Faturado por Serviço</h5>
                    {dadostotfatserv.length > 0 ? (
                      dadostotfatserv.map(function (item, i) {
                        return (
                          <li key={i}>{item.Servico} - {item.ValorFaturado}</li>
                        );
                      }
                      )
                    ) : ""
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-lg-12">
              <div class="card">
                <div class="card-header">
                  Cancelamento de NFSe em lote
                </div>
                <CancelamentoNfseLote />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-lg-12">
              <div class="card">
                <div class="card-header">
                  Notas Fiscais Autorizadas {qtdmovimentos ? ' - Total: ' + qtdmovimentos : ''}
                  <div class="card-action">
                    <div class="dropdown">
                      <a
                        href="javascript:void();"
                        class="dropdown-toggle dropdown-toggle-nocaret"
                        data-toggle="dropdown"
                      >
                        <i class="icon-options"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item" href="javascript:void();" onClick={CarregarMovimento}>
                          Recarregar Notas
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <Tabela dados={dadostabela} dadosPref={dadosprefeitura} load={isloadtabela} />
              </div>
            </div>
          </div>
          <div class="overlay toggle-menu"></div>
        </div>
      </div>
      <a href="javaScript:void();" class="back-to-top">
        <i class="fa fa-angle-double-up"></i>{" "}
      </a>
    </div>
  );
}
