import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import Tabela from "../../components/Tabela/TabelaMovimento";
import Menu from "../../components/Menu/Menu";
import Navbar from "../../components/Navbar/Navbar";
import moment from 'moment';
import "react-widgets/styles.css";
import logo from "../../assets/images/icon-application-w.png";

import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { ControlPointDuplicateOutlined, FormatAlignJustifyTwoTone } from "@material-ui/icons";
import Icon, { FontAwesome, Feather, AntDesign } from "react-web-vector-icons";
import Contexto from "../../components/Contexto/Contexto";

const api = process.env.REACT_APP_HOST;
const tbc = process.env.REACT_APP_HOST_2;

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));


export default function Movimentos() {
  const history = useHistory();
  const classes = useStyles();
  const [isload, setIsload] = useState(false);
  const [qtdmovimentos, setQtdmovimentos] = useState(0);
  const [dadostabela, setDadostabela] = useState([]);
  const [dadosprefeitura, setDadosprefeitura] = useState([]);
  const [dadostotfatserv, setDadostotfatserv] = useState([]);
  const [dadostotafat, setDadostotafat] = useState([]);
  const [isloadtabela, setIsloadtabela] = useState(false);

  useEffect(() => {
    const codusuario = localStorage.getItem("@integrador-app/codusuario");
    if (codusuario === null) {
      history.push("/entrar");
    } else {
      const coligada = localStorage.getItem("@integrador-app/codcoligada");
      if (coligada === null) {
        Swal.fire({
          title:
            '<span style="font-size: 22px;">Movimentos Pendentes</span>',
          html: '<p style="font-size: 16px;">Você deve informar um contexto para a exibição dos movimentos.</p>',
          icon: "error",
          confirmButtonText: "Sair",
        });
      } else {
        setIsloadtabela(true);

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Basic YTI4MDVhMTgtYjUwMC00ZGM0LWI3NDUtMjNmZDc0MjAwNzAw");

        var reqOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };

        fetch(`https://api.enotasgw.com.br/v1/estados/cidades/${localStorage.getItem("@integrador-app/codmunicip")}/Provedor`, reqOptions)
          .then(response => response.text())
          .then(result => {
            //console.log(result);
            setDadosprefeitura(result);
          })
          .catch(error => console.log('error', error));

        // Carrego o Movimento
        const params = {
          "codcoligada": localStorage.getItem("@integrador-app/codcoligada"),
          "codfilial": localStorage.getItem("@integrador-app/codfilial"),
          "codtitmov": localStorage.getItem("@integrador-app/codtpmov"),
          "dataini": localStorage.getItem("@integrador-app/dtini"),
          "datafim": localStorage.getItem("@integrador-app/dtfim")
        }

        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(params),
        };
        fetch(`${api}/movimentos`, requestOptions)
        .then(response => response.json())
        .then(
          result => { 
            if (result.status_code == "400") {
              setQtdmovimentos(0);
              localStorage.setItem('@integrador-app/qtdmov', result.record_count);
              setDadostabela([]);
              setIsloadtabela(false);
            } else {
              setDadostabela(result.dados);
              setQtdmovimentos(result.record_count)
              localStorage.setItem('@integrador-app/qtdmov', result.record_count);
              setIsloadtabela(false);
            }
        });

        /* Totaliza o Total Faturado por Serviço */
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Cookie", "ci_session=rjbl8vc5ogh5p2j5j3nqnbng3ccth84b");

        const requestServicosTotal = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(params),
        };

        fetch(`${api}/totalfaturadoporservico`, requestServicosTotal)
        .then(response => response.json())
        .then(
          result => { 
            setDadostotfatserv(result.dados);
          })
          .catch(error => console.log('error', error));

        /* Totaliza o Total A Faturar */
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Cookie", "ci_session=rjbl8vc5ogh5p2j5j3nqnbng3ccth84b");

        const requestTotal = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(params),
        };

        fetch(`${api}/totalafaturar`, requestTotal)
        .then(response => response.json())
        .then(
          result => { 
            setDadostotafat(result.dados);
          })
          .catch(error => console.log('error', error));
      }
    }
  }, []);

  function EnviarTodasNotas(){
    const movimento = dadostabela;
    //console.log(movimento[0].IdMovimento);
    //EnviaTodasNota(movimento[0].IdMovimento);
      for (const envio of movimento) {
        EnviaTodasNota(envio.IdMovimento);
      }
  }

  function ConsultarTodasNotas(){
    const movimento = dadostabela;
      for (const consulta of movimento) {
        ConsultaNota(consulta.IdMovimento);
      }
      Swal.fire({
        title:
          '<span style="font-size: 22px;">Consulta de nota fiscal</span>',
        html: '<p>O Processo de consulta de todas as notas foram finalizadas só ficaram na lista de pendentes de envio os movimentos que apresentarem problemas de dados. Recarregue a lista para atualizar os dados.</p>',
        icon: 'success',
        confirmButtonText: "Sair",
      });
  }

  function ConsultaNota(id) {
    setIsload(true);

    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Basic YTI4MDVhMTgtYjUwMC00ZGM0LWI3NDUtMjNmZDc0MjAwNzAw"
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `https://api.enotasgw.com.br/v1/empresas/${localStorage.getItem(
        "@integrador-app/idempresa"
      )}/nfes/porIdExterno/${id}`,
      requestOptions
    )
      .then((response) => {
        setIsload(false);
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((json) => {
        
        if (json.status === "Negada") {
          //console.log('IdMov: '+id+' - Status: Negada' , json);
        }

        if (json.status === "Autorizada") {
          console.log('IdMov: '+id+' - Status: Autorizada');
          
          var authHeaders = new Headers();
          authHeaders.append("Content-Type", "application/json");
          authHeaders.append(
            "Cookie",
            "ci_session=s1pgeqs2nifofho03ha1grpadv0ei5cm"
          );

          var corpo = JSON.stringify({
            codcoligada: localStorage.getItem("@integrador-app/codcoligada"),
            idmov: id,
            status: "A",
            numnfse: json.numero,
            xmlnfse: json.linkDownloadXML,
            obs: null,
            numprotocolo: json.codigoVerificacao,
            dtautoriza: moment(new Date()).format("YYYY-MM-DD"),
          });

          var requestParams = {
            method: "POST",
            headers: authHeaders,
            body: corpo,
            redirect: "follow",
          };

          fetch(
            `${api}/atualizastatusnfse`,
            requestParams
          )
            .then((response) => response.text())
            .then((result) => console.log(''))
            .catch((error) => console.log("error", error));
        }

        
      })
      .catch((error) => {
        //console.log('IDMov: '+id+' - erro no envio da nota', error)
      });
  }

  function EnviaTodasNota(id) {
    const prefeitura_dados = JSON.parse(dadosprefeitura);
    //console.log(prefeitura_dados);
    //console.log(prefeitura_dados.usaRegimeEspecialTributacao);
    
    if (dadosprefeitura !== null) {
      setIsload(true);

      var movHeaders = new Headers();
      movHeaders.append("Content-Type", "application/json");
      movHeaders.append(
        "Cookie",
        "ci_session=n2unia7lcanl13qq7tthast0d821n7ed"
      );

      var rawmov = JSON.stringify({
        codcoligada: localStorage.getItem("@integrador-app/codcoligada"),
        codfilial: localStorage.getItem("@integrador-app/codfilial"),
        idmov: id,
        codtitmov: localStorage.getItem("@integrador-app/codtpmov")
      });

      var requestOptions = {
        method: "POST",
        headers: movHeaders,
        body: rawmov,
        redirect: "follow",
      };

      fetch(
        `${api}/idmovimento`,
        requestOptions
      )
        .then((response) => {
          setIsload(false);
          return response.json();
        })
        .then((movimento) => {
          //console.log(movimento.dados);
          // Envio a nota
          var notaHeaders = new Headers();
          notaHeaders.append("Accept", "application/json");
          notaHeaders.append("Content-Type", "application/json");
          notaHeaders.append(
            "Authorization",
            "Basic YTI4MDVhMTgtYjUwMC00ZGM0LWI3NDUtMjNmZDc0MjAwNzAw"
          );

          async function alunoDadosNota(id, coligada, filial){
            var myHeaders = new Headers();
            myHeaders.append("Cookie", "ci_session=or8htv6bqvpffq0942kg5akdntasigap");
  
            var requestOptions = {
              method: 'GET',
              headers: myHeaders,
              redirect: 'follow'
            };
  
            var dados = await fetch(`${api}/getObsNota?codcoligada=${coligada}&codfilial=${filial}&idmov=${id}`, requestOptions)
              .then(response => response.json())
              .then(result => {
                return result.dados[0].dados;
              })
              .catch(error => console.log('error', error));
  
            if (dados) {
                return dados;
            } else {
                return null;
            }
          }


          alunoDadosNota(id, localStorage.getItem("@integrador-app/codcoligada"), localStorage.getItem("@integrador-app/codfilial"))
          .then((retorno) => {
            const dadosNota = {
              //ambienteEmissao: "homologacao",
              ambienteEmissao: "producao",
              tipo: "NFS-e",
              idExterno: id.toString(),
              cliente: {
                tipoPessoa: movimento.dados[0].PESSOAFISOUJUR,
                nome: movimento.dados[0].TomadorRazaoSocial,
                email: movimento.dados[0].TomadorContatoEmail,
                cpfCnpj: movimento.dados[0].TomadorIdentificacaoTomadorCpfCnpjCnpj,
                inscricaoMunicipal: null,
                inscricaoEstadual: null,
                telefone: movimento.dados[0].TomadorContatoTelefone,
                endereco: {
                  uf: movimento.dados[0].TomadorEnderecoUf,
                  cidade: movimento.dados[0].TomadorEnderecoCidade,
                  logradouro: movimento.dados[0].TomadorEnderecoEndereco,
                  numero: movimento.dados[0].TomadorEnderecoNumero,
                  complemento: movimento.dados[0].TomadorEnderecoComplemento,
                  bairro: movimento.dados[0].TomadorEnderecoBairro,
                  cep: movimento.dados[0].TomadorEnderecoCEP,
                },
              },
              enviarPorEmail: false,
              dataCompetencia: movimento.dados[0].DATA_COMPETENCIA,
              servico: {
                descricao: retorno ? retorno + ' - ' + movimento.dados[0].ServicoDiscriminacao : movimento.dados[0].ServicoDiscriminacao,
                aliquotaIss: parseFloat(
                  movimento.dados[0].ServicoValoresAliquota
                ),
                issRetidoFonte: false,
                codigoServicoMunicipio: prefeitura_dados.usaRegimeEspecialTributacao ? movimento.dados[0].ServicoCodigoTributacaoMunicipio : null,
                descricaoServicoMunicipio: movimento.dados[0].ServicoDiscriminacao,
                itemListaServicoLC116: prefeitura_dados.usaItemListaServico ? movimento.dados[0].ServicoItemListaServico : null,
                cnae: prefeitura_dados.usaCNAE ? movimento.dados[0].ServicoCodigoCnae : null,
                valorCofins: 0,
                valorCsll: 0,
                valorInss: 0,
                valorIr: 0,
                valorPis: 0,
              },
              valorTotal: parseFloat(
                movimento.dados[0].ServicoValoresValorLiquidoNfse
              ),
              observacoes: "",
              metadados: {},
            };

            var raw = JSON.stringify(dadosNota);
            console.log(dadosNota);

            var requestOptions = {
              method: "POST",
              headers: notaHeaders,
              body: raw,
              redirect: "follow",
            };
            setIsload(true);
            fetch(
              `https://api.enotasgw.com.br/v1/empresas/${localStorage.getItem(
                "@integrador-app/idempresa"
              )}/nfes/`,
              requestOptions
              )
              .then((response) => {
                setIsload(false);
                return response.json();
              })
              .then((json) => {
                console.log(json);
                if (json.nfeId !== undefined) {
                  Swal.fire({
                    title:
                      '<span style="font-size: 22px;">Envio de nota fiscal</span>',
                    html: '<p style="font-size: 16px;">Nota Fiscal enviada para a pefeitura, consulta a mesma para a verificação do Status na prefeitura.</p>',
                    icon: "success",
                    confirmButtonText: "Sair",
                  });
                } else {
                  Swal.fire({
                    title:
                      '<span style="font-size: 22px;">Envio de nota fiscal</span>',
                    html:
                      '<p style="font-size: 16px;">' + json[0].mensagem + "</p>",
                    icon: "error",
                    confirmButtonText: "Sair",
                  });
                }
              });
            })
            .catch((error) => console.log(error));
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    
  }

  function CarregarMovimento() {
    const coligada = localStorage.getItem("@integrador-app/codcoligada");
      if (coligada === null) {
        Swal.fire({
          title:
            '<span style="font-size: 22px;">Movimentos Pendentes</span>',
          html: '<p style="font-size: 16px;">Você deve informar um contexto para a exibição dos movimentos.</p>',
          icon: "error",
          confirmButtonText: "Sair",
        });
      } else {
        setIsloadtabela(true);
        // Carrego o Movimento
        const params = {
          "codcoligada": localStorage.getItem("@integrador-app/codcoligada"),
          "codfilial": localStorage.getItem("@integrador-app/codfilial"),
          "codtitmov": localStorage.getItem("@integrador-app/codtpmov"),
          "dataini": localStorage.getItem("@integrador-app/dtini"),
          "datafim": localStorage.getItem("@integrador-app/dtfim")
        }

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Basic YTI4MDVhMTgtYjUwMC00ZGM0LWI3NDUtMjNmZDc0MjAwNzAw");

        var reqOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };

        fetch(`https://api.enotasgw.com.br/v1/estados/cidades/${localStorage.getItem("@integrador-app/codmunicip")}/Provedor`, reqOptions)
          .then(response => response.text())
          .then(result => {
            setDadosprefeitura(result);
          })
          .catch(error => console.log('error', error));


        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(params),
        };

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Basic YTI4MDVhMTgtYjUwMC00ZGM0LWI3NDUtMjNmZDc0MjAwNzAw");

        

        fetch(`${api}/movimentos`, requestOptions)
        .then(response => response.json())
        .then(
          result => { 
            if (result.status_code == "400") {
              setQtdmovimentos(0);
              localStorage.setItem('@integrador-app/qtdmov', result.record_count);
              setDadostabela([]);
              setIsloadtabela(false);
            } else {
              setDadostabela(result.dados);
              setQtdmovimentos(result.record_count);
              localStorage.setItem('@integrador-app/qtdmov', result.record_count);
              setIsloadtabela(false);
            }
        });

        /* Totaliza o Total Faturado por Serviço */
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Cookie", "ci_session=rjbl8vc5ogh5p2j5j3nqnbng3ccth84b");

        const requestServicosTotal = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(params),
        };

        fetch((`${api}/totalfaturadoporservico`, requestServicosTotal)
        .then(response => response.json())
        .then(
          result => { 
            setDadostotfatserv(result.dados);
          })
          .catch(error => console.log('error', error)));


        /* Totaliza o Total a Faturar */
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Cookie", "ci_session=rjbl8vc5ogh5p2j5j3nqnbng3ccth84b");

        const requestTotal = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(params),
        };

        fetch((`${api}/totalafaturar`, requestTotal)
        .then(response => response.json())
        .then(
          result => { 
            setDadostotafat(result.dados);
          })
          .catch(error => console.log('error', error)));
      }
  }

  return (
    <div id="wrapper">
      {isload ? (
        <Backdrop className={classes.backdrop} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        ""
      )}
        <Menu />
      <header class="topbar-nav">
        <Navbar />
      </header>
      <div class="clearfix"></div>
      <div class="content-wrapper">
        <div class="container-fluid">
            <Contexto />
          <div class="row">
            <div class="col-12 col-lg-12">
              <div class="card">
                <div class="card-header">
                  Informações de Faturamento
                </div>
                <div class="row" style={{ margin: '15px' }}>
                  <div class="col-md-6">
                    <h5>Total a faturar no período</h5>
                    <p>
                      {dadostotafat ?
                      dadostotafat.map(function(item, i){
                        return(
                          <h4>{item.ValorAFaturar}</h4>
                        );
                      }) : ""}
                    </p><br/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-lg-12">
              <div class="card">
                <div class="card-header">
                  Movimento(s) Pedente de Faturamento { qtdmovimentos ? ' - Total: ' + qtdmovimentos : '' }
                  <div class="card-action">
                    <div class="dropdown">
                      <a
                        href="javascript:void();"
                        class="dropdown-toggle dropdown-toggle-nocaret"
                        data-toggle="dropdown"
                      >
                        <i class="icon-options"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item" href="javascript:void();" onClick={CarregarMovimento}>
                          <FontAwesome
                            name="refresh"
                            color="#fff"
                            size={20}
                          />
                          {" "}Recarregar Movimento
                        </a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item"  href="javascript:void();" onClick={EnviarTodasNotas}>
                          <FontAwesome
                            name="cloud-upload"
                            color="#fff"
                            size={20}
                          />
                          {" "}Enviar todas as Notas
                        </a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item"  href="javascript:void();" onClick={ConsultarTodasNotas}>
                          <FontAwesome
                            name="history"
                            color="#fff"
                            size={20}
                          />
                          {" "}Consultar todas as notas
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <Tabela dados={dadostabela} dadosPref={dadosprefeitura} load={isloadtabela} />
              </div>
            </div>
          </div>
          <div class="overlay toggle-menu"></div>
        </div>
      </div>
      <a href="javaScript:void();" class="back-to-top">
        <i class="fa fa-angle-double-up"></i>{" "}
      </a>
    </div>
  );
}
